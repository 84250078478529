import {
    ActionIcon,
    Button,
    Container,
    Group,
    LoadingOverlay,
    SimpleGrid,
    Text,
    TextInput,
    Textarea,
    Title,
} from '@mantine/core';
import { IconBrandInstagram, IconBrandTwitter, IconBrandYoutube } from '@tabler/icons-react';
import axios from 'axios';
import { useState } from 'react';
import { ContactIconsList } from './ContactIcon';
import classes from './ContactUs.module.css';

const social = [IconBrandTwitter, IconBrandYoutube, IconBrandInstagram];

export function ContactUs() {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState('');

    const icons = social.map((Icon, index) => (
        <ActionIcon key={index} size={28} className={classes.social} variant="transparent">
            <Icon size="1.4rem" stroke={1.5} />
        </ActionIcon>
    ));

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setLoading(true);
        setStatus('');

        try {
            await axios.post(
                '/api/ums/subscribe',
                { name, email, description: message },
                { headers: { 'X-App-UUID': '48e69687-98a2-45ce-b504-7bb65747425f' } }
            );
            setStatus('Thank you for your message. We will get back to you soon!');
            setName('');
            setEmail('');
            setMessage('');
        } catch (error) {
            setStatus('An error occurred. Please try again later.');
            console.error('Error submitting form:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container size={'xl'}>
            <div className={classes.wrapper} style={{ backgroundImage: `url(/images/contact_bg.webp)` }}>
                <SimpleGrid cols={{ base: 1, sm: 2 }} spacing={50}>
                    <div>
                        <Title className={classes.title}>Ready to Innovate?</Title>
                        <Text className={classes.description} mt="sm" mb={30}>
                            Please feel free to get in touch with us for any inquiries or questions. We are here to help!
                        </Text>

                        <ContactIconsList />

                        <Group mt="xl">{icons}</Group>
                    </div>
                    <div className={classes.form}>
                        <LoadingOverlay visible={loading} />
                        <form onSubmit={handleSubmit}>
                            <TextInput
                                label="Email"
                                placeholder="your@email.com"
                                required
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                classNames={{ input: classes.input, label: classes.inputLabel }}
                            />
                            <TextInput
                                label="Name"
                                placeholder="Your name"
                                mt="md"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                classNames={{ input: classes.input, label: classes.inputLabel }}
                            />
                            <Textarea
                                required
                                label="Your message"
                                placeholder="I want to know more about..."
                                minRows={4}
                                mt="md"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                classNames={{ input: classes.input, label: classes.inputLabel }}
                            />

                            {status && (
                                <Text color={status.includes('error') ? 'red' : 'green'} mt="md">
                                    {status}
                                </Text>
                            )}

                            <Group justify="flex-end" mt="md" id="contact_us">
                                <Button type="submit" className={classes.control} disabled={loading}>
                                    {loading ? 'Sending...' : 'Send message'}
                                </Button>
                            </Group>
                        </form>
                    </div>
                </SimpleGrid>
            </div>
        </Container>
    );
}