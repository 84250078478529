import { Box, Container, Image, SimpleGrid, Text, ThemeIcon, Title } from '@mantine/core';
import classes from './FeaturesImages.module.css';
import IndustriesServed from './IndustriesServed';

const data = [
    {
        image: '/images/cloud-build-svgrepo-com.svg',
        title: 'Cloud Computing & DevOps',
        description: 'AWS, Azure, GCP, MongoDb, Oracke, SQL Server, PostgresQL and more.',
    },
    {
        image: '/images/monitor-svgrepo-com.svg',
        title: 'Web & Full stack development',
        description: 'React, Angular, Vue, Node, Express, and more.',
    },
    {
        image: '/images/mobile-svgrepo-com.svg',
        title: 'Multi-Platform Mobile development',
        description: 'Flutter, React Native, and more.',
    },
];

export function FeaturesImages() {
    const items = data.map((item) => (
        <div className={classes.item} key={item.image}>
            <ThemeIcon variant="light" className={classes.itemIcon} size={60} radius="md">
                <Image src={item.image} style={{ fill: 'red' }} />
            </ThemeIcon>

            <div>
                <Text fw={700} fz="lg" className={classes.itemTitle}>
                    {item.title}
                </Text>
                <Text>{item.description}</Text>
            </div>
        </div>
    ));

    return (
        <>
            <Container size={'xl'} className={classes.wrapper}>
                <Title className={classes.title} order={2}>
                    eXonstech is a full service
                    consulting for AI, Cloud, Web & Mobile development.
                </Title>

                <Container size={660} p={0}>
                    <Text className={classes.description}>
                        We provide a wide range of services to help you grow your business. We are a team of
                        professionals who are passionate about what we do. Our goal is to provide the best
                        possible service to our clients and to help them achieve their business goals.
                    </Text>
                </Container>

                <SimpleGrid
                    cols={{ base: 1, sm: 2, md: 3 }}
                    spacing={{ base: 'md', sm: 'xl' }}
                    verticalSpacing={{ base: 'md', sm: 'xl' }}
                    mt={30}
                >
                    {items}
                </SimpleGrid>
            </Container>
            <Box style={{ backgroundColor: '#444' }}>
                <Container size="xl">
                    <IndustriesServed />
                </Container>
            </Box>
        </>
    );
}