import { Card, Container, List, SimpleGrid, Text, Title } from '@mantine/core';
import React from 'react';
import './IndustriesServed.css';

interface IndustryData {
    image: string;
    title: string;
    description: string[];
}

const industriesData: IndustryData[] = [
    {
        image: '/images/specialized-ai-svgrepo-com.svg',
        title: 'Specialized AI Services',
        description: [
            'Prompt Engineering (LangChain)',
            'Transformers & Langchain (HuggingFace)',
            'Voice & Video Cloning (Custom models, ElevenLabs, Descript)',
            'Text-to-Speech, Image, Video, Music (Stable Diffusion, DALL-E, Midjourney)'
        ],
    },
    {
        image: '/images/model-development-svgrepo-com.svg',
        title: 'Model Development',
        description: [
            'Fine-Tuning LLMs (HuggingFace Transformers)',
            'Custom GPTs (OpenAI GPT)',
            'Model Creation & Evaluation (TensorFlow, PyTorch)'
        ],
    },
    {
        image: '/images/data-processing-svgrepo-com.svg',
        title: 'Data Processing',
        description: [
            'Dataset Curation & Annotation (Labelbox)',
            'Data Preprocessing (Apache Spark)',
            'Feature Engineering (Scikit-learn)'
        ],
    },

    {
        image: '/images/agents-svgrepo-com.svg',
        title: 'Agents & Agentic AI',
        description: [
            'Multi-Agent Systems (MAS) Platforms',
            'Reinforcement Learning (Stable-Baselines3, RLlib)',
            'Auto-GPT'
        ],
    },
    {
        image: '/images/infrastructure-svgrepo-com.svg',
        title: 'Infrastructure',
        description: [
            'Cloud Platforms (AWS, GCP, Azure)',
            'GPU/TPU Clusters (NVIDIA)',
            'Distributed Computing (Kubernetes)'
        ],
    },
    {
        image: '/images/integration-svgrepo-com.svg',
        title: 'Integration & Deployment',
        description: [
            'API Integration (RESTful APIs, GraphQL)',
            'MLOps (MLflow)',
            'Containerization (Docker)'
        ],
    },
];

interface IndustryCardProps extends IndustryData { }

const IndustryCard: React.FC<IndustryCardProps> = ({ image, title, description }) => (
    <Card shadow="sm" padding="lg" radius="md" withBorder className="industry-card">
        <Card.Section className="card-image-section">
            <Text className="card-title">{title}</Text>
        </Card.Section>


        <List size="sm" spacing="xs" className="card-list">
            {description.map((item, index) => (
                <List.Item key={index}>{item}</List.Item>
            ))}
        </List>
    </Card>
);

const IndustriesServed: React.FC = () => {
    return (
        <Container size="xl" className="card">
            <Title order={2} className="section-title">Our AI Platform</Title>

            <SimpleGrid
                cols={{ base: 1, sm: 2, md: 3 }}
                spacing={{ base: 'md', sm: 'lg' }}
                verticalSpacing={{ base: 'md', sm: 'lg' }}
            >
                {industriesData.map((industry, index) => (
                    <IndustryCard key={index} {...industry} />
                ))}
            </SimpleGrid>
        </Container>
    );
};

export default IndustriesServed;