import { Carousel } from '@mantine/carousel';
import {
    Card,
    Container,
    Image,
    rem,
    SimpleGrid,
    Text,
    ThemeIcon,
    useMantineTheme
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import ReactPlayer from "react-player";
import { ActionsGrid, AI_TOOLKIT } from '../services/ActionsGrid';
import classes from './FeaturesCards.module.css';

const mockdata = [
    {
        title: 'ExonSphere: Encompassing Intelligent Healthcare',
        description:
            'Pioneering AI-driven healthcare solutions for precision oncology, telehealth, and holistic patient care. Our custom AI agent framework leverages SMART on FHIR to seamlessly integrate with EMR/EHR systems, revolutionizing biomarker identification, clinical decision support, and medical records management.',
        icon: 'logo.png',
        image: '/images/exonsphere.png',
    },
    {
        title: 'AI Training, Education, and Skill Development',
        description:
            'Our YouTube channel delivers continuous tutorials and insights, providing viewers with cutting-edge AI techniques and strategies for practical application, fostering innovation and real-world problem solving. Subscribe to our channel.',
        icon: '/images/meeting-presentation-training-svgrepo-com.svg',
        video: 'https://www.youtube.com/watch?v=GTiQnf8EU0k'
    },
    {
        title: 'AI Content Creation & Production',
        description:
            'Specializing in Voice & Video Cloning, Post-Production, Text-to-Speech, Image, Video, Music generation, Face Fusion, and Lip Sync, we offer comprehensive solutions for dynamic multimedia content creation and enhancement',
        icon: '/images/ai-platform-svgrepo-com.svg',
        video: 'https://www.youtube.com/watch?v=Vlke0UrtKuk'
    },
    {
        title: 'AI-Driven Digital Media Strategy',
        description:
            'By leveraging AI, we transform your digital media strategy, elevating content personalization, audience engagement, and data-driven decision-making to dynamically enhance your brand\'s online presence and performance.',
        icon: '/images/video-intelligence-api-svgrepo-com.svg',
        video: 'https://www.youtube.com/watch?v=XPE2Qp9RILs'
    },
];

export function FeaturesCards() {
    const theme = useMantineTheme();
    const xsScreen = window.innerWidth <= 48 * 16;
    const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

    const renderFeature = (feature: any) => (
        <div>
            <ThemeIcon variant="transparent" size={60}>
                <Image src={feature.icon} />
            </ThemeIcon>
            <Text fz="xl" fw={500} className={classes.cardTitle} mt="md">
                {feature.title}
            </Text>
            <Text fz="md" mt="sm">
                {feature.description}
            </Text>
        </div>
    );

    const renderImage = (feature: any) => (
        <div className={classes.cardImage}>
            <Image src={feature.image} />
        </div>
    );

    const renderVideo = (feature: any) => (
        <div className={classes.cardImage}>
            <ReactPlayer
                width='100%'
                height={xsScreen ? '26vh' : '30vh'}
                url={feature.video}
            />
        </div>
    );

    const renderCard = (feature: any, index: number) => (
        <Card key={feature.title} shadow="md" radius="md" className={classes.card}>
            <SimpleGrid cols={{ base: 1, sm: 1, md: 2 }} spacing="xl">
                {/* {index % 2 === 1 || xsScreen ? ( */}
                <>
                    {renderFeature(feature)}
                    {feature.video ? renderVideo(feature) : renderImage(feature)}
                </>
                {/* ) : (
                    <>
                        {feature.video ? renderVideo(feature) : renderImage(feature)}
                        {renderFeature(feature)}
                    </>
                )} */}
            </SimpleGrid>
        </Card>
    );

    return (
        <Container size={'xl'}>
            <SimpleGrid cols={{ base: 1, sm: 1, md: 1 }} spacing="md" mt='xl'>
                <ActionsGrid title='AI toolkit' data={AI_TOOLKIT} />
            </SimpleGrid>
            <SimpleGrid cols={{ base: 1, md: 1 }} spacing="xl" mt={50}>
                {renderCard(mockdata[0], 0)}
                <Carousel
                    withIndicators
                    loop
                    slideSize={{ base: mobile ? '100%' : '70%', sm: mobile ? '100%' : '75%' }}
                    slideGap={{ base: 2, sm: 'xl' }}
                    align="start"
                    slidesToScroll={mobile ? 1 : 2}
                    nextControlIcon={<IconChevronRight style={{ color: 'white', background: 'gray', borderRadius: '50%', width: rem(50), height: rem(50) }} />}
                    previousControlIcon={<IconChevronLeft style={{ color: 'white', background: 'gray', borderRadius: '50%', width: rem(50), height: rem(50) }} />}
                    style={{ padding: 0 }}
                >
                    {mockdata.slice(1).map((feature, index) => (
                        <Carousel.Slide key={feature.title}>
                            {renderCard(feature, index + 1)}
                        </Carousel.Slide>
                    ))}
                </Carousel>
                <br />
            </SimpleGrid>
        </Container>
    );
}